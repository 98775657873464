import React from 'react'
import config from 'config'
import { checkWebView } from 'helpers'
import links, { routeData } from 'links'
import { Switch, Route, Redirect } from 'router'

import { useMunicipalities, usePurchasedModules } from 'models/city'

import MainLayout from 'layouts/MainLayout/MainLayout'
import AdminLayout from 'layouts/AdminLayout/AdminLayout'

import { HasAccess, HasUserProperty } from 'containers'
import { loadable, viewLoadable } from 'containers/Loadable/Loadable'
import LoginResultPage from 'pages/LoginResultPage/LoginResultPage'

import { AdminHomePageSkeleton } from 'compositions/admin/mapLayers'

import ImmortalsContainer from 'layers/immortals/pages/containers/ImmortalsContainer/ImmortalsContainer'


const isVolgodonsk = Boolean(config.cityName === 'Волгодонск')

const { isIos } = checkWebView(!__SERVER__ ? navigator.userAgent : '')

// User
//  ↳ Root
const RootHomePage = loadable(() => import('layers/root/pages/HomePage/HomePage'))
const WeatherPage = loadable(() => import('layers/root/pages/WeatherPage/WeatherPage'))
const CreateRoutePage = loadable(() => import('pages/CreateRoutePage/CreateRoutePage'))
const PublicOfferPage = loadable(() => import('pages/PublicOfferPage/PublicOfferPage'))
const SupportForm = loadable(() => import('compositions/form/SupportForm/SupportForm'))
const NoTransportPage = loadable(() => import('pages/NoTransportPage/NoTransportPage'))
const MunicipalitySelectPage = loadable(() => import('layers/root/pages/MunicipalitySelectPage/MunicipalitySelectPage'), { ssr: false })
const UpdatePasswordPage = loadable(() => import('pages/UpdatePasswordPage/UpdatePasswordPage'))
//  ↳ Profile
const ProfileHomePage = loadable(() => import('layers/profile/pages/HomePage/HomePage'), { ssr: false })
const ProfileEditCounterPage = loadable(
  () => import('layers/profile/pages/EditCounterPage/EditCounterPage'),
  { fallback: <AdminHomePageSkeleton />, ssr: false })
const ProfileEditPulseCounterPage = loadable(
  () => import('layers/counters/admin/EditPulseCounterPage/EditPulseCounterPage'),
  { fallback: <AdminHomePageSkeleton />, ssr: false })
const ProfileCounterDetailsPage = loadable(
  () => import('layers/profile/pages/CounterDetailsPage/CounterDetailsPage'),
  { fallback: <AdminHomePageSkeleton />, ssr: false })
const ProfileEditMonitorPage = loadable(
  () => import('layers/profile/pages/EditMonitorPage/EditMonitorPage'),
  { fallback: <AdminHomePageSkeleton /> })
const ProfileEditSchedulePage = loadable(
  () => import('layers/profile/pages/EditSchedulePage/EditSchedulePage'),
  { fallback: <AdminHomePageSkeleton /> })
//  ↳ Appeals
const AppealsHomePage = loadable(() => import('layers/appeals/pages/HomePage/HomePage'))
const AppealPage = loadable(() => import('layers/appeals/pages/AppealPage/AppealPage'))
const AddAppealPage = loadable(() => import('layers/appeals/pages/AddAppealPage/AddAppealPage'))
const ControlAppealPage = loadable(() => import('layers/appeals/pages/ControlAppealPage/ControlAppealPage'))
//  ↳ Police
const PoliceHomePage = loadable(() => import('layers/police/pages/HomePage/HomePage'))
//  ↳ Elections
const ElectionsHomePage = loadable(() => import('layers/elections/pages/HomePage/HomePage'))
//  ↳ Institutions
const InstitutionsHomePage = loadable(() => import('layers/institutions/pages/HomePage/HomePage'))
//  ↳ Edu
const EduHomePage = loadable(() => import('layers/marketplace/edu/pages/HomePage/HomePage'))
const EduEditOrderPage = loadable(() => import('layers/marketplace/edu/pages/EditOrderPage/EditOrderPage'))
const EduOrderPage = loadable(() => import('layers/marketplace/edu/pages/OrderPage/OrderPage'))
const EduRepeatOrder = loadable(() => import('layers/marketplace/edu/pages/RepeatOrderPage/RepeatOrderPage'))
//  ↳ Sport
const SportHomePage = loadable(() => import('layers/marketplace/sport/pages/HomePage/HomePage'))
const SportToolPage = loadable(() => import('layers/marketplace/sport/pages/ToolPage/ToolPage'))
const SportOrderPage = loadable(() => import('layers/marketplace/sport/pages/OrderPage/OrderPage'))
const SportBookingPage = loadable(() => import('layers/marketplace/sport/pages/BookingPage/BookingPage'))
const SportConditionsPage = loadable(() => import('layers/marketplace/sport/pages/ConditionsPage/ConditionsPage'))
//  ↳ Immortals
const ImmortalsHomePage = loadable(() => import('layers/immortals/pages/HomePage/HomePage'))
const ImmortalsHeroPage = loadable(() => import('layers/immortals/pages/HeroPage/HeroPage'))
//  ↳ Public Transport
const PublicTransportHomePage = loadable(() => import('layers/publicTransport/pages/HomePage/HomePage'), { ssr: false })
const PublicTransportRouteDetailsPage = loadable(() => import('layers/publicTransport/pages/RouteDetailsPage/RouteDetailsPage'), { ssr: false })
const PublicTransportStationDetailsPage = loadable(() => import('layers/publicTransport/pages/StationDetailsPage/StationDetailsPage'), { ssr: false })
//  ↳ Accessibility
const AccessibilityHomePage = loadable(() => import('layers/accessibility/pages/HomePage/HomePage'), { ssr: false })
//  ↳ Medscan
const MedScanAuth = loadable(() => import('layers/medscan/containers/MedScanAuth/MedScanAuth'), { ssr: false })
const MedscanHomePage = loadable(() => import('layers/medscan/pages/HomePage/HomePage'), { ssr: false })
const MedscanLoginPage = loadable(() => import('layers/medscan/pages/MedscanLoginPage/MedscanLoginPage'), { ssr: false })
const MedscanRecordPage = loadable(() => import('layers/medscan/pages/RecordPage/RecordPage'), { ssr: false })
//  ↳ Notification
const NotificationListPage = loadable(() => import('src/pages/NotificationListPage/NotificationListPage'))

// Admin
//  ↳ Root
const AdminHomePage = loadable(
  () => import('layers/root/admin/AdminHomePage/AdminHomePage'),
  { fallback: <AdminHomePageSkeleton /> })
//  ↳ Chat
const ChatAdminHomePage = loadable(
  () => import('layers/chat/admin/HomePage/HomePage'),
  { fallback: <AdminHomePageSkeleton /> })
//  ↳ Institutions
const InstitutionsAdminHomePage = loadable(
  () => import('layers/institutions/admin/pages/AdminHomePage/AdminHomePage'),
  { fallback: <AdminHomePageSkeleton /> })
const InstitutionEditObjectPage = loadable(
  () => import('layers/institutions/admin/pages/EditObjectPage/EditObjectPage'),
  { fallback: <AdminHomePageSkeleton /> })
//  ↳ Police
const PoliceAdminHomePage = loadable(
  () => import('layers/police/admin/AdminHomePage/AdminHomePage'),
  { fallback: <AdminHomePageSkeleton /> })
const PoliceEditDepartmentPage = loadable(
  () => import('layers/police/admin/EditAreaPage/EditAreaPage'),
  { fallback: <AdminHomePageSkeleton /> })
const PoliceEditPolicemanPage = loadable(
  () => import('layers/police/admin/EditPolicemanPage/EditPolicemanPage'),
  { fallback: <AdminHomePageSkeleton /> })
const PoliceEditDocumentPage = loadable(
  () => import('layers/police/admin/EditDocumentPage/EditDocumentPage'),
  { fallback: <AdminHomePageSkeleton /> })
//  ↳ Elections
const ElectionsAdminHomePage = loadable(
  () => import('layers/elections/admin/AdminHomePage/AdminHomePage'),
  { fallback: <AdminHomePageSkeleton /> })
const ElectionsEditDistrictPage = loadable(
  () => import('layers/elections/admin/EditDistrictPage/EditDistrictPage'),
  { fallback: <AdminHomePageSkeleton /> })
const ElectionsEditAreaPage = loadable(
  () => import('layers/elections/admin/EditAreaPage/EditAreaPage'),
  { fallback: <AdminHomePageSkeleton /> })
const ElectionsEditItemPage = loadable(
  () => import('layers/elections/admin/EditDeputyPage/EditDeputyPage'),
  { fallback: <AdminHomePageSkeleton /> })
const ElectionsEditDocumentPage = loadable(
  () => import('layers/elections/admin/EditDocumentPage/EditDocumentPage'),
  { fallback: <AdminHomePageSkeleton /> })
const ElectionsEditAddressPage = loadable(
  () => import('layers/elections/admin/EditAddressPage/EditAddressPage'),
  { fallback: <AdminHomePageSkeleton /> })
//  ↳ Appeals
const AppealsAdminHomePage = loadable(
  () => import('layers/appeals/admin/HomePage/HomePage'),
  { fallback: <AdminHomePageSkeleton /> })
const AppealsAdminItemPage = loadable(
  () => import('layers/appeals/admin/ItemPage/ItemPage'),
  { fallback: <AdminHomePageSkeleton /> })
//  ↳ Counters
const CountersHomePage = loadable(
  () => import('layers/counters/admin/HomePage/HomePage'),
  { fallback: <AdminHomePageSkeleton /> })
const CountersEditCounterPage = loadable(
  () => import('layers/counters/admin/EditCounterPage/EditCounterPage'),
  { fallback: <AdminHomePageSkeleton /> })
const CountersEditPulseCounterPage = loadable(
  () => import('layers/counters/admin/EditPulseCounterPage/EditPulseCounterPage'),
  { fallback: <AdminHomePageSkeleton /> })
const CountersCounterDetailsPage = loadable(
  () => import('layers/counters/admin/CounterDetailsPage/CounterDetailsPage'),
  { fallback: <AdminHomePageSkeleton /> })
const CountersEditConsumerPage = loadable(
  () => import('layers/counters/admin/EditConsumerPage/EditConsumerPage'),
  { fallback: <AdminHomePageSkeleton /> })
const CountersEditResourcePage = loadable(
  () => import('layers/counters/admin/EditResourcePage/EditResourcePage'),
  { fallback: <AdminHomePageSkeleton /> })
const CountersEditSupplierPage = loadable(
  () => import('layers/counters/admin/EditSupplierPage/EditSupplierPage'),
  { fallback: <AdminHomePageSkeleton /> })
const CountersEditMonitorPage = loadable(
  () => import('layers/counters/admin/EditMonitorPage/EditMonitorPage'),
  { fallback: <AdminHomePageSkeleton /> })
const CountersEditSchedulePage = loadable(
  () => import('layers/counters/admin/EditSchedulePage/EditSchedulePage'),
  { fallback: <AdminHomePageSkeleton /> })
//  ↳ Users
const UsersHomePage = loadable(
  () => import('layers/users/admin/HomePage/HomePage'),
  { fallback: <AdminHomePageSkeleton /> })
const UsersEditUserPage = loadable(
  () => import('layers/users/admin/EditUserPage/EditUserPage'),
  { fallback: <AdminHomePageSkeleton /> })
const UsersAddUserPage = loadable(
  () => import('layers/users/admin/AddUserPage/AddUserPage'),
  { fallback: <AdminHomePageSkeleton /> })
const UsersAddRolePage = loadable(
  () => import('layers/users/admin/AddRolePage/AddRolePage'),
  { fallback: <AdminHomePageSkeleton /> })
const UsersEditRolePage = loadable(
  () => import('layers/users/admin/EditRolePage/EditRolePage'),
  { fallback: <AdminHomePageSkeleton /> })
const UsersViewRolePage = loadable(
  () => import('layers/users/admin/ViewRolePage/ViewRolePage'),
  { fallback: <AdminHomePageSkeleton /> })
//  ↳ Settings
const SettingsHomePage = loadable(
  () => import('layers/settings/admin/HomePage/HomePage'),
  { fallback: <AdminHomePageSkeleton /> })
const AppealsSettingsHomePage = loadable(
  () => import('layers/settings/admin/AppealsSettingsHomePage/AppealsSettingsHomePage'),
  { fallback: <AdminHomePageSkeleton /> })
const ClearingSettingsHomePage = loadable(
  () => import('layers/settings/admin/ClearingSettingsHomePage/ClearingSettingsHomePage'),
  { fallback: <AdminHomePageSkeleton /> })
const ExcavationSettingsHomePage = loadable(
  () => import('layers/settings/admin/ExcavationSettingsHomePage/ExcavationSettingsHomePage'),
  { fallback: <AdminHomePageSkeleton /> })
const RccSettingsPage = loadable(
  () => import('layers/settings/admin/RccSettingsPage/RccSettingsPage'),
  { fallback: <AdminHomePageSkeleton /> })
const UsersSettingsPage = loadable(
  () => import('layers/settings/admin/UsersSettingsPage/UsersSettingsPage'),
  { fallback: <AdminHomePageSkeleton /> })
const AlertsSettingsHomePage = loadable(
  () => import('layers/settings/admin/AlertsSettingsHomePage/AlertsSettingsHomePage'),
  { fallback: <AdminHomePageSkeleton /> })
const StatsSettingsHomePage = loadable(
  () => import('layers/settings/admin/StatsSettingsHomePage/StatsSettingsHomePage'),
  { fallback: <AdminHomePageSkeleton /> })
const WeatherSettingsHomePage = loadable(
  () => import('layers/settings/admin/WeatherSettingsHomePage/WeatherSettingsHomePage'),
  { fallback: <AdminHomePageSkeleton /> })
//  ↳ Edu
const EduAdminHomePage = loadable(
  () => import('layers/marketplace/edu/admin/HomePage/HomePage'),
  { fallback: <AdminHomePageSkeleton /> })
const EduEditSupplierPage = loadable(
  () => import('layers/marketplace/edu/admin/EditSupplierPage/EditSupplierPage'),
  { fallback: <AdminHomePageSkeleton /> })
const EduEditSchoolPage = loadable(
  () => import('layers/marketplace/edu/admin/EditSchoolPage/EditSchoolPage'),
  { fallback: <AdminHomePageSkeleton /> })
const EduEditCategoryPage = loadable(
  () => import('layers/marketplace/edu/admin/EditCategoryPage/EditCategoryPage'),
  { fallback: <AdminHomePageSkeleton /> })
//  ↳ Sport
const SportAreasListPage = loadable(
  () => import('layers/marketplace/sport/admin/HomePage/HomePage'),
  { fallback: <AdminHomePageSkeleton /> })
const SportEditAreaPage = loadable(
  () => import('layers/marketplace/sport/admin/EditAreaPage/EditAreaPage'),
  { fallback: <AdminHomePageSkeleton /> })
const SportAddToolPage = loadable(
  () => import('layers/marketplace/sport/admin/AddToolPage/AddToolPage'),
  { fallback: <AdminHomePageSkeleton /> })
const SportAddGroupPage = loadable(
  () => import('layers/marketplace/sport/admin/AddGroupPage/AddGroupPage'),
  { fallback: <AdminHomePageSkeleton /> })
const SportEditGroupPage = loadable(
  () => import('layers/marketplace/sport/admin/EditGroupPage/EditGroupPage'),
  { fallback: <AdminHomePageSkeleton /> })
const SportEditToolPage = loadable(
  () => import('layers/marketplace/sport/admin/EditToolPage/EditToolPage'),
  { fallback: <AdminHomePageSkeleton /> })
const SportToolsListPage = loadable(
  () => import('layers/marketplace/sport/admin/ToolsListPage/ToolsListPage'),
  { fallback: <AdminHomePageSkeleton /> })
const SportToolSchedulePage = loadable(
  () => import('layers/marketplace/sport/admin/ToolSchedulePage/ToolSchedulePage'),
  { fallback: <AdminHomePageSkeleton /> })
const SportEditTariffPage = loadable(
  () => import('layers/marketplace/sport/admin/EditTariffPage/EditTariffPage'),
  { fallback: <AdminHomePageSkeleton /> })
const SportGroupPage = loadable(
  () => import('layers/marketplace/sport/admin/GroupPage/GroupPage'),
  { fallback: <AdminHomePageSkeleton /> })

//  ↳ Public transport
const PublicTransportVolgodonskAdminHomePage = loadable(
  () => import('layers/publicTransport/admin/VolgodonskHomePage/VolgodonskHomePage'),
  { fallback: <AdminHomePageSkeleton /> })
const PublicTransportAdminHomePage = loadable(
  () => import('layers/publicTransport/admin/AdminHomePage/AdminHomePage'),
  { fallback: <AdminHomePageSkeleton /> })
const PublicTransportEditRoutePage = loadable(
  () => import('layers/publicTransport/admin/EditRoutePage/EditRoutePage'),
  { fallback: <AdminHomePageSkeleton /> })
const PublicTransportEditStationPage = loadable(
  () => import('layers/publicTransport/admin/EditStationPage/EditStationPage'),
  { fallback: <AdminHomePageSkeleton /> })
const PublicTransportEditPeriodPage = loadable(
  () => import('layers/publicTransport/admin/EditPeriodPage/EditPeriodPage'),
  { fallback: <AdminHomePageSkeleton /> })
const PublicTransportEditDriverPage = loadable(
  () => import('layers/publicTransport/admin/EditDriverPage/EditDriverPage'),
  { fallback: <AdminHomePageSkeleton /> })
const PublicTransportEditCashierPage = loadable(
  () => import('layers/publicTransport/admin/EditCashierPage/EditCashierPage'),
  { fallback: <AdminHomePageSkeleton /> })
//  ↳ Service transport
const ServiceTransportAdminHomePage = loadable(
  () => import('layers/serviceTransport/admin/AdminHomePage/AdminHomePage'),
  { fallback: <AdminHomePageSkeleton /> })
const ServiceTransportAddTransportPage = loadable(
  () => import('layers/serviceTransport/admin/AddTransportPage/AddTransportPage'),
  { fallback: <AdminHomePageSkeleton /> })
const ServiceTransportEditTransportPage = loadable(
  () => import('layers/serviceTransport/admin/EditTransportPage/EditTransportPage'),
  { fallback: <AdminHomePageSkeleton /> })
const ServiceTransportAddOwnerPage = loadable(
  () => import('layers/serviceTransport/admin/AddOwnerPage/AddOwnerPage'),
  { fallback: <AdminHomePageSkeleton /> })
const ServiceTransportEditOwnerPage = loadable(
  () => import('layers/serviceTransport/admin/EditOwnerPage/EditOwnerPage'),
  { fallback: <AdminHomePageSkeleton /> })
const TdpAddOwnerPage = loadable(
  () => import('layers/serviceTransport/admin/AddTdpPage/AddTdpPage'),
  { fallback: <AdminHomePageSkeleton /> })
const TdpEditOwnerPage = loadable(
  () => import('layers/serviceTransport/admin/EditTdpPage/EditTdpPage'),
  { fallback: <AdminHomePageSkeleton /> })
//  ↳ Immortals
const ImmortalsAdminHomePage = loadable(
  () => import('layers/immortals/admin/AdminHomePage/AdminHomePage'),
  { fallback: <AdminHomePageSkeleton /> })
const ImmortalsAdminHeroPage = loadable(
  () => import('layers/immortals/admin/AdminHeroPage/AdminHeroPage'),
  { fallback: <AdminHomePageSkeleton /> })
//  ↳ Accessibility
const AccessibilityAdminHomePage = loadable(
  () => import('layers/accessibility/admin/AdminHomePage/AdminHomePage'),
  { fallback: <AdminHomePageSkeleton /> })
const AccessibilityEditItemPage = loadable(
  () => import('layers/accessibility/admin/EditItemPage/EditItemPage'),
  { fallback: <></> })
//  ↳ Asudd
const AsuddHomePage = loadable(() => import('layers/asudd/admin/AdminHomePage/AdminHomePage'), { ssr: false })
const EditTrafficLightPage = loadable(
  () => import('layers/asudd/admin/EditTrafficLightPage/EditTrafficLightPage'),
  { fallback: <></> })
const EditRoadPage = loadable(
  () => import('layers/asudd/admin/EditRoadPage/EditRoadPage'),
  { fallback: <></> })

const AdminDefaultPage = loadable(
  () => import('pages/AdminDefaultPage/AdminDefaultPage'),
  { fallback: <></> })

// Login
const LoginPage = loadable(() => import('pages/LoginPage/LoginPage'))
const LogoutPage = loadable(() => import('pages/LogoutPage/LogoutPage'))
const ConfirmEmailPage = loadable(() => import('pages/ConfirmEmailPage/ConfirmEmailPage'))

// 404
const Page404 = loadable(() => import('pages/Page404/Page404'))
// 403
const Page403 = loadable(() => import('pages/Page403/Page403'))


const Routes = () => {
  const { purchasedModuleTags, isPurchasedModuleTagsFetching } = usePurchasedModules()
  const { isRegion, isRootDomain, isMunicipalitiesFetching } = useMunicipalities()

  const routes = [
    // User
    //  ↳ Root
    { path: links.home, component: (isRegion && isRootDomain) ? MunicipalitySelectPage : RootHomePage },
    { path: links.weather, component: WeatherPage },
    { path: links.createRoute, component: CreateRoutePage },
    { path: links.privacy, component: PublicOfferPage },
    { path: links.support, component: SupportForm },
    { path: links.noTransport, component: NoTransportPage },
    { path: links.updatePassword, component: UpdatePasswordPage },
    //  ↳ Appeals
    { path: links.appeals.root, component: AppealsHomePage },
    { path: links.appeals.add, component: AddAppealPage },
    { path: links.appeals.item.root, component: AppealPage },
    { path: links.appeals.item.control, component: ControlAppealPage },
    { path: links.appeals.item.edit, component: AddAppealPage },
    //  ↳ Police
    { path: links.police.root, component: PoliceHomePage },
    { path: links.police.item, component: PoliceHomePage },
    //  ↳ Elections
    { path: links.elections.root, component: ElectionsHomePage },
    { path: links.elections.item, component: ElectionsHomePage },
    //  ↳ Institutions
    { path: links.institutions.root, component: InstitutionsHomePage },
    { path: links.institutions.item, component: InstitutionsHomePage },
    //  ↳ Edu
    { path: links.edu.root, component: EduHomePage },
    { path: links.edu.order.editOrder, component: EduEditOrderPage },
    { path: links.edu.order.root, component: EduOrderPage },
    { path: links.edu.order.repeatOrder, component: EduRepeatOrder },
    //  ↳ Sport
    { path: links.sport.root, component: SportHomePage },
    { path: links.sport.conditions, component: SportConditionsPage },
    { path: links.sport.tool.root, component: SportToolPage },
    { path: links.sport.tool.bookingSchedule, component: SportBookingPage },
    { path: links.sport.tool.bookingSubscription, component: SportBookingPage },
    { path: links.sport.order.id, component: SportOrderPage },
    //  ↳ Immortals
    {
      Container: ImmortalsContainer,
      containerRoutes: [
        { path: links.immortals.root, component: ImmortalsHomePage },
        { path: links.immortals.item, component: ImmortalsHeroPage },
      ],
    },
    //  ↳ Transport
    { path: links.transport.root, component: PublicTransportHomePage },
    { path: links.transport.route.details, component: PublicTransportRouteDetailsPage },
    { path: links.transport.stations.details, component: PublicTransportStationDetailsPage },
    //  ↳ Accessibility
    { path: links.accessibility.root, component: AccessibilityHomePage },
    //  ↳ Medscan
    {
      Container: MedScanAuth,
      containerRoutes: [
        { path: links.medscan.root, component: MedscanHomePage, container: MedScanAuth },
        { path: links.medscan.record, component: MedscanRecordPage, container: MedScanAuth },
      ],
    },
    //  ↳ Notification
    { path: links.notification.root, component: NotificationListPage },
  ]
    .filter(({ path, containerRoutes }) => {
      if (containerRoutes) {
        return containerRoutes.some(({ path }) => {
          const tag = routeData[path].tag

          return !tag || purchasedModuleTags?.includes(tag as UserModel.PublicModuleTag)
        })
      }
      else {
        const tag = routeData[path].tag

        return !tag || purchasedModuleTags?.includes(tag as UserModel.PublicModuleTag)
      }
    })

  const isFetching = isMunicipalitiesFetching || isPurchasedModuleTagsFetching

  if (isFetching) {
    return <></>
  }

  return (
    <Switch>
      <Route path={links.login} component={LoginPage} />
      <Route path={links.logout} component={LogoutPage} />
      <Route path={links.loginError} component={LoginResultPage} />
      <Route path={links.loginSuccess} component={LoginResultPage} />
      <Route path={links.loginStatus} component={LoginResultPage} />
      <Route path={links.confirmEmail} component={ConfirmEmailPage} />
      <MainLayout>
        {
          routes.map(({ path, component, Container, containerRoutes }) => (
            Container
              ? (
                <Container key={path}>
                  {
                    containerRoutes.map(({ path, component }) => (
                      <Route key={path} path={path} exact component={component} />
                    ))
                  }
                </Container>
              )
              : (
                <Route key={path} path={path} exact component={component} />
              )
          ))
        }
        {/* Profile */}
        <Route path={links.reactProfile.root} exact component={ProfileHomePage} />
        <Route path={links.reactProfile.editCounter} exact component={ProfileEditCounterPage} />
        <Route path={links.reactProfile.addCounter} exact component={ProfileEditCounterPage} />
        <Route path={links.reactProfile.editPulseCounter} exact component={ProfileEditPulseCounterPage} />
        <Route path={links.reactProfile.addPulseCounter} exact component={ProfileEditPulseCounterPage} />
        <Route path={links.reactProfile.counterDetails.root} exact component={ProfileCounterDetailsPage} />
        <Route path={links.reactProfile.counterDetails.editMonitor} exact component={ProfileEditMonitorPage} />
        <Route path={links.reactProfile.counterDetails.addMonitor} exact component={ProfileEditMonitorPage} />
        <Route path={links.reactProfile.counterDetails.editSchedule} exact component={ProfileEditSchedulePage} />
        <Route path={links.reactProfile.counterDetails.addSchedule} exact component={ProfileEditSchedulePage} />
        <Route path={links.page403} component={Page403} />
        <Route path={links.page404} component={Page404} />
      </MainLayout>
      <AdminLayout>
        <HasUserProperty propertyName="adminAccess">
          {/* Admin */}
          {/* ↳ Root */}
          <Route path={links.admin.root} exact component={AdminDefaultPage} />

          <HasAccess link={links.admin.scud} roles={[ 'OPERATOR', 'MODERATOR', 'ADMIN' ]}>
            <Route path={links.admin.scud} exact component={AdminHomePage} />
          </HasAccess>

          {/*  ↳ Chat */}
          <HasAccess link={links.admin.chat} roles={[ 'OPERATOR', 'MODERATOR', 'ADMIN' ]}>
            <Route path={links.admin.chat} exact component={ChatAdminHomePage} />
          </HasAccess>

          {/*  ↳ Police */}
          <HasAccess link={links.admin.police.root} roles={[ 'ADMIN' ]}>
            <Route path={links.admin.police.root} exact component={PoliceAdminHomePage} />
            <Route path={links.admin.police.addArea} exact component={PoliceEditDepartmentPage} />
            <Route path={links.admin.police.editArea} exact component={PoliceEditDepartmentPage} />
            <Route path={links.admin.police.addPoliceman} exact component={PoliceEditPolicemanPage} />
            <Route path={links.admin.police.editPoliceman} exact component={PoliceEditPolicemanPage} />
            <Route path={links.admin.police.addDocument} exact component={PoliceEditDocumentPage} />
            <Route path={links.admin.police.editDocument} exact component={PoliceEditDocumentPage} />
          </HasAccess>

          {/*  ↳ Elections */}
          <HasAccess link={links.admin.elections.root} roles={[ 'ADMIN' ]}>
            <Route path={links.admin.elections.root} exact component={ElectionsAdminHomePage} />
            <Route path={links.admin.elections.addDistrict} exact component={ElectionsEditDistrictPage} />
            <Route path={links.admin.elections.editDistrict} exact component={ElectionsEditDistrictPage} />
            <Route path={links.admin.elections.addArea} exact component={ElectionsEditAreaPage} />
            <Route path={links.admin.elections.editArea} exact component={ElectionsEditAreaPage} />
            <Route path={links.admin.elections.addDeputy} exact component={ElectionsEditItemPage} />
            <Route path={links.admin.elections.editDeputy} exact component={ElectionsEditItemPage} />
            <Route path={links.admin.elections.addDocument} exact component={ElectionsEditDocumentPage} />
            <Route path={links.admin.elections.editDocument} exact component={ElectionsEditDocumentPage} />
            <Route path={links.admin.elections.addAddress} exact component={ElectionsEditAddressPage} />
            <Route path={links.admin.elections.editAddress} exact component={ElectionsEditAddressPage} />
          </HasAccess>

          {/*  ↳ Immortals */}
          <HasAccess link={links.admin.immortals.root} roles={[ 'OPERATOR', 'MODERATOR', 'ADMIN' ]}>
            <Route path={links.admin.immortals.root} exact component={ImmortalsAdminHomePage} />
            <Route path={links.admin.immortals.item} exact component={ImmortalsAdminHeroPage} />
            <Route path={links.admin.immortals.add} exact component={ImmortalsAdminHeroPage} />
          </HasAccess>

          {/*  ↳ Appeals */}
          <HasAccess link={links.admin.appeals.root} roles={[ 'OPERATOR', 'MODERATOR', 'ADMIN' ]}>
            <Route path={links.admin.appeals.root} exact component={AppealsAdminHomePage} />
            <Route path={links.admin.appeals.item} exact component={AppealsAdminItemPage} />
          </HasAccess>

          {/*  ↳ Institutions */}
          <HasAccess link={links.admin.institutions.root} roles={[ 'ADMIN' ]}>
            <Route path={links.admin.institutions.root} exact component={InstitutionsAdminHomePage} />
            <Route path={links.admin.institutions.addObject} exact component={InstitutionEditObjectPage} />
            <Route path={links.admin.institutions.editObject} exact component={InstitutionEditObjectPage} />
          </HasAccess>

          {/*  ↳ Counters */}
          <HasAccess link={links.admin.counters.root} roles={[ 'OPERATOR', 'MODERATOR', 'ADMIN' ]}>
            <Route path={links.admin.counters.root} exact component={CountersHomePage} />
            <HasAccess link={links.admin.counters.root} roles={[ 'ADMIN' ]}>
              <Route path={links.admin.counters.editCounter} exact component={CountersEditCounterPage} />
              <Route path={links.admin.counters.addCounter} exact component={CountersEditCounterPage} />
              <Route path={links.admin.counters.editPulseCounter} exact component={CountersEditPulseCounterPage} />
              <Route path={links.admin.counters.addPulseCounter} exact component={CountersEditPulseCounterPage} />
              <Route path={links.admin.counters.editConsumer} exact component={CountersEditConsumerPage} />
              <Route path={links.admin.counters.addConsumer} exact component={CountersEditConsumerPage} />
              <Route path={links.admin.counters.editResource} exact component={CountersEditResourcePage} />
              <Route path={links.admin.counters.addResource} exact component={CountersEditResourcePage} />
              <Route path={links.admin.counters.editSupplier} exact component={CountersEditSupplierPage} />
              <Route path={links.admin.counters.addSupplier} exact component={CountersEditSupplierPage} />
            </HasAccess>
            <Route path={links.admin.counters.counterDetails.root} exact component={CountersCounterDetailsPage} />
            <HasAccess link={links.admin.counters.root} roles={[ 'ADMIN', 'MODERATOR' ]}>
              <Route path={links.admin.counters.counterDetails.editMonitor} exact component={CountersEditMonitorPage} />
              <Route path={links.admin.counters.counterDetails.addMonitor} exact component={CountersEditMonitorPage} />
              <Route path={links.admin.counters.counterDetails.editSchedule} exact component={CountersEditSchedulePage} />
              <Route path={links.admin.counters.counterDetails.addSchedule} exact component={CountersEditSchedulePage} />
            </HasAccess>
          </HasAccess>

          {
            config.isLegacyAuth ? (
              <>
                {/*  ↳ Users */}
                <HasUserProperty propertyName="adminEditAccess">
                  <Route path={links.admin.users.root} exact component={UsersHomePage} />
                  <Route path={links.admin.users.addUser} exact component={UsersAddUserPage} />
                </HasUserProperty>
                <HasUserProperty propertyName="adminAccess"> {/* TODO check rights inside this components - OPERATOR can access this route*/}
                  <Route path={links.admin.users.editUser.root} exact component={UsersEditUserPage} />
                  <Route path={links.admin.users.editUser.viewRole} exact component={UsersViewRolePage} />
                  <Route path={links.admin.users.editUser.editRole} exact component={UsersEditRolePage} />
                  <Route path={links.admin.users.editUser.addRole} exact component={UsersAddRolePage} />
                </HasUserProperty>

                {/*  ↳ Settings */}
                <HasUserProperty propertyName="root">
                  <Route path={links.admin.settings.root} exact component={SettingsHomePage} />
                  <Route path={links.admin.settings.modules.appeals} exact component={AppealsSettingsHomePage} />
                  <Route path={links.admin.settings.modules.clearing} exact component={ClearingSettingsHomePage} />
                  <Route path={links.admin.settings.modules.excavation} exact component={ExcavationSettingsHomePage} />
                  <Route path={links.admin.settings.modules.alerts} exact component={AlertsSettingsHomePage} />
                  <Route path={links.admin.settings.modules.stats} exact component={StatsSettingsHomePage} />
                  <Route path={links.admin.settings.modules.weather} exact component={WeatherSettingsHomePage} />
                  <Route path={links.admin.settings.modules.users} exact component={UsersSettingsPage} />
                </HasUserProperty>
              </>
            ) : (
              <>
                {/*  ↳ Users */}
                <HasAccess link={links.admin.users.root} roles={[ 'ADMIN', 'MODERATOR', 'OPERATOR' ]}>
                  <Route path={links.admin.users.root} exact component={UsersHomePage} />
                  <Route path={links.admin.users.addUser} exact component={UsersAddUserPage} />
                  <Route path={links.admin.users.editUser.root} exact component={UsersEditUserPage} />
                  <Route path={links.admin.users.editUser.viewRole} exact component={UsersViewRolePage} />
                  <Route path={links.admin.users.editUser.editRole} exact component={UsersEditRolePage} />
                  <Route path={links.admin.users.editUser.addRole} exact component={UsersAddRolePage} />
                  <Route path={links.admin.settings.modules.alerts} exact component={AlertsSettingsHomePage} />
                </HasAccess>
                {/*  ↳ Settings */}
                <HasAccess link={links.admin.settings.root} roles={[ 'ADMIN', 'MODERATOR' ]}>
                  <Route path={links.admin.settings.root} exact component={SettingsHomePage} />
                  <Route path={links.admin.settings.modules.appeals} exact component={AppealsSettingsHomePage} />
                  <Route path={links.admin.settings.modules.clearing} exact component={ClearingSettingsHomePage} />
                  <Route path={links.admin.settings.modules.excavation} exact component={ExcavationSettingsHomePage} />
                  <Route path={links.admin.settings.modules.rcc} exact component={RccSettingsPage} />
                  <Route path={links.admin.settings.modules.users} exact component={UsersSettingsPage} />
                </HasAccess>
              </>
            )
          }

          {/*  ↳ Edu */}
          <HasAccess link={links.admin.edu.root} roles={[ 'ADMIN', 'MODERATOR', 'OPERATOR' ]}>
            <Route path={links.admin.edu.root} exact component={EduAdminHomePage} />
          </HasAccess>
          <HasAccess link={links.admin.edu.root} roles={[ 'ADMIN' ]}>
            <Route path={links.admin.edu.suppliers.add} exact component={EduEditSupplierPage} />
            <Route path={links.admin.edu.suppliers.edit} exact component={EduEditSupplierPage} />
          </HasAccess>
          <HasAccess link={links.admin.edu.root} roles={[ 'MODERATOR' ]}>
            <Route path={links.admin.edu.schools.add} exact component={EduEditSchoolPage} />
            <Route path={links.admin.edu.schools.edit} exact component={EduEditSchoolPage} />
            <Route path={links.admin.edu.categories.add} exact component={EduEditCategoryPage} />
            <Route path={links.admin.edu.categories.edit} exact component={EduEditCategoryPage} />
          </HasAccess>

          {/*  ↳ Sport */}
          <HasAccess link={links.admin.sport.root} roles={[ 'ADMIN', 'MODERATOR', 'OPERATOR' ]}>
            <Route path={links.admin.sport.root} exact component={SportAreasListPage} />
            <HasAccess link={links.admin.sport.root} roles={[ 'ADMIN' ]}>
              <Route path={links.admin.sport.add} exact component={SportEditAreaPage} />
              <Route path={links.admin.sport.area.root} exact component={SportEditAreaPage} />
            </HasAccess>
            <Route path={links.admin.sport.area.tools} exact component={SportToolsListPage} />
            <Route path={links.admin.sport.area.tool.schedule} exact component={SportToolSchedulePage} />
            <HasAccess link={links.admin.sport.root} roles={[ 'ADMIN', 'MODERATOR' ]}>
              <Route path={links.admin.sport.area.addTool} exact component={SportAddToolPage} />
              <Route path={links.admin.sport.area.tool.root} exact component={SportEditToolPage} />
              <Route path={links.admin.sport.area.tool.addTariff} exact component={SportEditTariffPage} />
              <Route path={links.admin.sport.area.tool.addGroup} exact component={SportAddGroupPage} />
              <Route path={links.admin.sport.area.tool.group.item.editGroup} exact component={SportEditGroupPage} />
              <Route path={links.admin.sport.area.tool.tariff} exact component={SportEditTariffPage} />
              <Route path={links.admin.sport.area.tool.group.item.root} exact component={SportGroupPage} />
            </HasAccess>
          </HasAccess>
          {/*  ↳ Public transport */}
          <HasAccess link={links.admin.transport.root} roles={[ 'ADMIN', 'OPERATOR' ]}>
            <Route path={links.admin.transport.root} exact component={isVolgodonsk ? PublicTransportVolgodonskAdminHomePage : PublicTransportAdminHomePage } />
            <Route path={links.admin.transport.routes.add} exact component={PublicTransportEditRoutePage} />
            <Route path={links.admin.transport.routes.edit} exact component={PublicTransportEditRoutePage} />
            <Route path={links.admin.transport.stations.add} exact component={PublicTransportEditStationPage} />
            <Route path={links.admin.transport.stations.edit} exact component={PublicTransportEditStationPage} />
            <Route path={links.admin.transport.periods.edit} exact component={PublicTransportEditPeriodPage} />
            <Route path={links.admin.transport.periods.add} exact component={PublicTransportEditPeriodPage} />
            <Route path={links.admin.transport.drivers.add} exact component={PublicTransportEditDriverPage} />
            <Route path={links.admin.transport.drivers.edit} exact component={PublicTransportEditDriverPage} />
            <Route path={links.admin.transport.cashiers.add} exact component={PublicTransportEditCashierPage} />
            <Route path={links.admin.transport.cashiers.edit} exact component={PublicTransportEditCashierPage} />
          </HasAccess>
          {/*  ↳ Service transport */}
          <HasAccess link={links.admin.serviceTransport.root} roles={[ 'ADMIN', 'MODERATOR', 'OPERATOR' ]}>
            <Route path={links.admin.serviceTransport.root} exact component={ServiceTransportAdminHomePage} />
            <Route path={links.admin.serviceTransport.transport.add} exact component={ServiceTransportAddTransportPage} />
            <Route path={links.admin.serviceTransport.transport.edit} exact component={ServiceTransportEditTransportPage} />
            <Route path={links.admin.serviceTransport.owners.add} exact component={ServiceTransportAddOwnerPage} />
            <Route path={links.admin.serviceTransport.owners.edit} exact component={ServiceTransportEditOwnerPage} />
            <Route path={links.admin.serviceTransport.tdps.add} exact component={TdpAddOwnerPage} />
            <Route path={links.admin.serviceTransport.tdps.edit} exact component={TdpEditOwnerPage} />
          </HasAccess>
          {/*  ↳ Accessibility */}
          <HasAccess link={links.admin.accessibility.root} roles={[ 'ADMIN', 'OPERATOR' ]}>
            <Route path={links.admin.accessibility.root} exact component={AccessibilityAdminHomePage} />
            <Route path={links.admin.accessibility.add} exact component={AccessibilityEditItemPage} />
            <Route path={links.admin.accessibility.edit} exact component={AccessibilityEditItemPage} />
          </HasAccess>
          {/*  ↳ Asudd */}
          <HasAccess link={links.admin.asudd.root} roles={[ 'ADMIN', 'MODERATOR', 'OPERATOR' ]}>
            <Route path={links.admin.asudd.root} exact component={AsuddHomePage} />
            <Route path={links.admin.asudd.trafficLights.edit} exact component={EditTrafficLightPage} />
            <Route path={links.admin.asudd.roads.edit} exact component={EditRoadPage} />
          </HasAccess>
        </HasUserProperty>
      </AdminLayout>
      <MainLayout>
        <Route path="*" component={Page404} />
      </MainLayout>
    </Switch>
  )
}


export default Routes